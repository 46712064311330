.w {
    width: 1000px;
    margin: 0 auto;
}
.hide {
    display: none;
}
a {
    text-decoration: none;
    color: #666;
}
.icon-font {
    font-family: iconfont,sans-serif;
    font-style: normal;
}