.admin-nav {
    overflow: hidden;
    border-bottom: 1px solid red;
}
.admin-nav-ul {
    position: relative;
    margin: 5px auto;
    display: block;
    list-style-type: disc;
    color: #999;
}
.admin-nav-ul li {
    cursor: pointer;
    position: relative;
    list-style: none;
    float: left;
    padding: 5px 15px;
}
.admin-nav-checking {
    background-color: red;
    color: white;
}


.o-m-box {
    position: relative;
}
.o-m-left {
    width: 200px;
    position: relative;
    float: left;
}
.o-m-right {
    width: 800px;
    position: relative;
    float: right;
}
.o-m-right-head {
    padding: 10px 0px 5px 15px;
    color: #666;
}
.o-m-right-head-icon {
    vertical-align: middle;
    margin-right: 10px;
    height: 20px;
    display: inline-block;
    width: 3px;
    background-color: red;
}
.o-m-left-ul {
    color: #666;
    overflow: hidden;
    position: relative;
    margin: 5px auto;
    display: block;
    list-style-type: disc;
}
.o-m-left-ul li {
    cursor: pointer;
    text-align: center;
    width: 100%;
    position: relative;
    list-style: none;
    float: left;
    padding: 5px 0px;
    border-bottom: 1px solid #ddd;
}
.o-m-left-ul-li-checking {
    color: red;
}
.o-m-unfinished-order-content {
    position: relative;
}
.o-m-u-o-i-head {
    color: #666;
    background-color: #f5f5f5;
    position: relative;
    font-size: 12px;
    padding: 5px 0px;
}
.o-m-u-o-i-head span {
    text-align: center;
    display: inline-block;
}
.o-m-u-o-item {
    margin: 5px 0px;
    border: 1px solid #ddd;
    position: relative;
    overflow: hidden;
}
.o-m-u-o-i-content {
    float: none;
    position: relative;
    overflow: hidden;
}
.o-m-u-o-i-content div {
    float: left;
}
.o-m-u-o-item:hover {
    border-color: #999;
}
.o-m-u-o-i-id {
    text-align: center;
    width: 100px;
}
.o-m-u-o-i-created {
    color: #666;
    text-align: center;
    width: 300px;
}
.o-m-u-o-i-control {
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 150px;
}
.o-m-u-o-i-control div {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: none;
    display: block;
    margin: 5px;
}
.o-m-u-o-i-control div:hover {
    color: red;
}