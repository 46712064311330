.pay-btns-box {
    width: 260px;
    margin: 0 auto;
}
.pay-btn {
    margin: 0px 15px;
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 34px;
    width: 100px;
    font-size: 16px;
    border-radius: 0;
    border: none;
    color: white;
}