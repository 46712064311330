#content {
    clear: both;
}
.cart-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.cart-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.cart-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.cart-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.cart-notice-text {
    border: 3px dotted rgb(241, 98, 32);
    width: 700px;
    height: 400px;
    line-height: 400px;
    text-align: center;
    color: rgb(241, 98, 32);
    font-size: 20px;
    font-weight: 700;
    margin: 50px auto;
}
.cart-box {
    position: relative;
}
.cart-box-head {
    margin-top: 20px;
    font-size: 12px;
    position: relative;
    border: 1px solid #bbb;
    background-color: #eee;
    padding: 5px 0px;
}
.cart-item {
    margin: 10px 0px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    font-size: 12px;
    color: #111;
    border: 2px;
    border-left: 0px;
    border-right: 0px;
    border-style: solid;
    border-color: #999;
}
.cart-cell {
    float: left;
    padding: 5px;
}
.cart-item-img {
    width: 100px;
    height: 100px;
}
.cart-item-title {
    cursor: pointer;
    width: 200px;
}
.cart-item-title:hover {
    color: red;
}
.cart-item-params {
    margin-left: 30px;
    width: 150px;
}
.cart-item-price {
    text-align: center;
    margin-left: 30px;
    width: 100px;
}
.cart-item-num {
    width: 100px;
}
.cart-item-total {
    margin-left: 30px;
    width: 100px;
}
.cart-item-btns {
    text-align: center;
    width: 50px;
}
.cart-item-btn-delete {
    cursor: pointer;
}
.cart-item-btn-delete:hover {
    color: red;
}
.cart-box-foot {
    overflow: hidden;
    position: relative;
    padding: 5px;
    border: 1px solid #ddd;
    border-left: 0px;
    border-right: 0px;
}
.cart-box-foot-total {
    margin-top: 4px;
    float: right;
    color: red;
    font-size: 18px;
    font-weight: 500;
    margin-right: 50px;
}
.cart-box-foot-total-head {
    font-size: 12px;
    color: #999;
}
.cart-box-foot-btn {
    text-align: center;
    display: block;
    float: right;
    margin-right: 20px;
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 34px;
    width: 60px;
    font-size: 16px;
    line-height: 34px;
    border-radius: 0;
    border: none;
    color: white;
}