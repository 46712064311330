.footer-box {
    padding: 30px 0;
    position: relative;
    font-size: 12px;
    color: #999;
}
.footer-links {
    position: relative;
    text-align: center;
}
.footer-link {
    margin: 0 20px;
}
.footer-copy-right {
    margin-top: 10px;
    text-align: center;
}