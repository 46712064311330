#content {
    clear: both;
}
.user-col-menu {
    width: 200px;
    float: left;
}
.user-col-content {
    border: 1px solid #ddd;
    width: 798px;
    float: right;
}
.user-menu {
    width: 200px;
}
.user-menu-head {
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    border: 1px solid #ddd;
    border-bottom: 0px;
}
.user-menu-tree {
    border: 1px solid #ddd;
}
.user-menu-tree li {
    list-style: none;
}
.user-menu-tree li>a {
    display: block;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
}
.user-menu-tree li:hover {
    background-color: #ddd;
}
.user-menu-tree-item-checking {
    cursor: default;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: white;
    background-color: red;
}
.user-menu-tree li a {
    text-decoration: none;
    list-style: none;
    font-size: 12px;
}

.user-info-box {
    position: relative;
}
.user-box-head {
    position: relative;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
    font-size: 15px;
    vertical-align: middle;
}
.user-box-head-icon {
    float: left;
    position: absolute;
    top: 5px;
    background-color: red;
    left: 10px;
    width: 5px;
    height: 18px;
    margin-top: 4px;
    vertical-align: middle;
}

.info-box-item {
    margin: 40px 0px;
    overflow: hidden;
    clear: both;
    position: relative;
}
.info-box-item-head {
    margin-top: 7px;
    font-size: 14px;
    color: #48576a;
    text-align: right;
    width: 150px;
    float: left;
    vertical-align: middle;
}
.info-box-item-content {
    vertical-align: middle;
    margin-left: 15px;
    float: left;
}
.info-box-item-input {
    height: 35px;
    width: 250px;
    font-size: inherit;
    background-image: none;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: 0;
    padding: 5px 4px;
}
.info-box-split-line {
    height: 0;
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #ddd;
}
.info-box-save-btn {
    text-align: center;
    line-height: 35px;
    margin: 40px auto;
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 35px;
    width: 100px;
    font-size: 16px;
    border-radius: 0;
    border: none;
    color: white;
}


.receiverAddr-table {
    margin: 30px auto;
    font-size: 12px;
    border-collapse: collapse;
    border: 1px solid #ddd;
}
.receiverAddr-tr td {
    padding: 12px 16px;
    border: 1px solid #ddd;
}
.receiverAddr-tr th {
    text-align: left;
    font-weight: 400;
    padding-left: 10px;
    padding: 6px 16px;
    border: 1px solid #ddd;
}
.receiverAddr-head th {
    background-color: #ebecf0;
}
.addr-notice-text {
    margin: 30px 0px 30px 30px;
    font-size: 15px;
    color: #999;
}


.myOrder-tab-box {
    clear: both;
    overflow: hidden;
    position: relative;
}
.myOrder-tab-box-item {
    cursor: pointer;
    float: left;
    text-align: center;
    color: #666;
    padding: 10px 20px;
}
.myOrder-tab-box-item-checking {
    color: white;
    background-color: red;
}
.myOrder-head {
    overflow: hidden;
    font-size: 14px;
    background-color: #f5f5f5;
    color: #666;
}
.myOrder-head>div {
    padding: 10px;
    float: left;
    text-align: center;
}
.myOrder-item {
    position: relative;
    border: 1px solid #ddd;
    margin: 10px 0px;
}
.myOrder-item:hover {
    border-color: #999;
}
.myOrder-item-head {
    font-size: 12px;
    color: #666;
    background-color: #f5f5f5;
    padding: 10px;
}
.myOrder-commodity-item {
    font-size: 12px;
    overflow: hidden;
    position: relative;
}
.myOrder-commodity-item>div {
    padding: 10px;
}
.myOrder-commodity-info {
    width: 295px;
    float: left;
    overflow: hidden;
    position: relative;
}
.myOrder-commodity-img {
    float: left;
    width: 80px;
    height: 80px;
}
.myOrder-commodity-title {
    margin-left: 15px;
    float: left;
    width: 200px;
    color: #999;
}
.myOrder-commodity-title:hover {
    color: red;
    cursor: pointer;
}
.myOrder-commodity-price {
    width: 100px;
    text-align: center;
    float: left;
}
.myOrder-commodity-num {
    width: 50px;
    text-align: center;
    float: left;
}
.myOrder-commodity-status {
    width: 100px;
    text-align: center;
    float: left;
}
.myOrder-commodity-status-i-d {
    margin-top: 5px;
    cursor: pointer;
}
.myOrder-commodity-status-i-d:hover {
    color: red;
}
.myOrder-commodity-control {
    text-align: center;
    width: 100px;
    float: left;
}
.myOrder-i-d-box {
    position: absolute;
    width: 800px;
    height: 100%;
    z-index: 100;
    background: rgba(102,102,102, 0.6);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99666666, endColorstr=#99666666);
}
.myOrder-i-d-quit {
    position: absolute;
    right: 5%;
    top: 5%;
    font-size: 50px;
}
.myOrder-i-d-quit:before {
    content: "\2715";
    cursor: pointer;
    color: #4cb0f9;
}
.myOrder-i-d-content {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.myOrder-i-d-c-head {
    background-color: white;
    padding: 10px 0px;
    font-size: 15px;
    color: #666;
    width: 100%;
    text-align: center;
}
.myOrder-i-d-c-content {
    background-color: #ebecf0;
    font-size: 12px;
    color: #999;
    max-height: 500px;
    overflow-y: scroll;
}
.myOrder-i-d-c-c-item {
    margin-top: 1px;
    padding: 10px;
    overflow: hidden;
    background-color: white;
    position: relative;
}
.myOrder-i-d-c-c-i-left {
    float: left;
    position: relative;
}
.myOrder-i-d-c-c-i-right {
    margin-top: 8px;
    float: right;
}
.myOrder-i-d-c-c-i-bottom {
    color: #666;
}
.myOrder-i-d-c-c-btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: red;
    padding: 10px 0px;
    font-size: 16px;
    color: white;
}


.comment-commodity-info-box {
    margin: 20px 0px;
    padding: 10px;
    border: 2px solid #666;
    border-width: 1px 0px 1px 0px;
}
.comment-commodity-info-img {
    width: 150px;
    height: 150px;
}
.comment-commodity-info-title {
    margin: 10px 30px;
    vertical-align: top;
    display: inline-block;
    font-size: 15px;
    color: #666;
}
.comment-box {
    width: 600px;
    margin: 0 auto;
}
.comment-head {
    color: #666;
    font-size: 15px;
    vertical-align: middle;
}
.comment-stars-ul {
    margin-left: 50px;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    position: relative;
}
.comment-stars-ul li {
    margin: 0px 2px;
    font-size: 30px;
    cursor: pointer;
    list-style: none;
    float: left;
}
.comment-desc {
    display: inline-block;
    resize: none;
    vertical-align: top;
    width: 450px;
    margin-left: 50px;
    padding: 10px;
    height: 200px;
}
.comment-btn {
    margin: 20px auto;
    width: 100px;
    text-align: center;
    cursor: pointer;
    background-color: red;
    padding: 10px 15px;
    font-size: 16px;
    color: white;
}