.w {
    width: 1000px;
    margin: 0 auto;
}
.hide {
    display: none;
}
a {
    text-decoration: none;
    color: #666;
}
.icon-font {
    font-family: iconfont,sans-serif;
    font-style: normal;
}
.footer-box {
    padding: 30px 0;
    position: relative;
    font-size: 12px;
    color: #999;
}
.footer-links {
    position: relative;
    text-align: center;
}
.footer-link {
    margin: 0 20px;
}
.footer-copy-right {
    margin-top: 10px;
    text-align: center;
}
#logo {
    position: relative;
    float: left;
    width: 50px;
    height: 50px;
}
#content {
    clear: both;
}
.login-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.login-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.login-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.login-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.login-wrapper {
    height: 500px;
    position: relative;
}
.login-form {
    padding: 20px;
    margin-top: 50px;
    position: relative;
    float: right;
    z-index: 4;
    background-color: white;
}
.login-form-item {
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}
.login-form-title {
    margin-right: 20px;
    text-align: right;
    font-size: 20px;
    font-weight: 350;
    color: red;
    padding-bottom: 20px;
}
.login-item-head {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    float: left;
    font-size: 12px;
    color: #eee;
    background: #aaa;
}
.login-item-content {
    float: left;
}
.login-form-input {
    font-size: 12px;
    width: 250px;
    height: 18px;
    outline: none;
    padding: 10px;
    border: 1px solid #aaa;
    vertical-align: middle;
}
.form-forget-password {
    float: right;
    cursor: pointer;
    font-size: 12px;
    color: #222222;
}
.form-forget-password:hover {
    color: red;
    text-decoration: underline;
    -webkit-text-decoration-color: red;
            text-decoration-color: red;
}
.form-free-register {
    margin-right: 10px;
    float: right;
    cursor: pointer;
    font-size: 12px;
    color: #222222;
}
.form-free-register:hover {
    color: red;
    text-decoration: underline;
    -webkit-text-decoration-color: red;
            text-decoration-color: red;
}
.form-login-btn {
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 35px;
    width: 100%;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.login-form-error {
    color: red;
    margin-bottom: 10px;
    font-size: 12px;
}
.login-banner {
    position: absolute;
    height: 500px;
    left: 0;
    top: 0;
    width: 100%;
}
.banner-bg {
    position: relative;
    z-index: 3;
    height: 500px;
}
.clearfix::after {
    content: ".";
    visibility: hidden;
    overflow: hidden;
    height: 0;
    display: block;
    clear: both;
}
.chat-img {
    max-width: 100px;
    display: block;
}
.chat-img::after {
    content: ".";
    height: 0;
    visibility: hidden;
    clear: both;
}
#chattingBox {
    margin-top: 10px;
    position: relative;
    width: 100%;
}
.chatting-Head {
    border-radius: 5px 5px 0 0;
    color: white;
    background-color: black;
    width: 100%;
    min-width: 350px;
    height: 30px;
    line-height: 30px;
    cursor: move;
}
.chatting-Head-Quit {
    float: left;
    position: relative;
}
.chatting-Head-Quit:hover {
    cursor: pointer;
}
.chatting-Head-Quit-InnerA {
    margin-top: 5px;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-bottom: transparent solid 20px;
    border-top: transparent solid 20px;
    border-right: white solid 25px;
}
.chatting-Head-Quit-InnerB {
    top: 5px;
    left: 15px;
    width: 0;
    height: 0;
    border-bottom: transparent solid 20px;
    border-top: transparent solid 20px;
    border-right: black solid 25px;
    position: absolute;
}
.chatting-Head-Title {
    text-align: center;
    font-size: 20px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
.chatting-Head-Setting {
    cursor: pointer;
    float: right;
}
.chatting-Head-Setting-Inner {
    /*border: white solid 5px;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-right: 10px;*/
    height: 25px;
}
.chatting-Body {
    cursor: default;
    min-width: 350px;
    background-color: white;
    width: 100%;
}
.chatting-Body-TextArea {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 10px;
    width: 100%;
    height: 300px;
}
.chatting-Body-TextArea::-webkit-scrollbar{
    width:5px;
    height:5px;
}
.chatting-Body-TextArea::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius:2px;
}
.chatting-Body-TextArea::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius:2px;
}
.chatting-Body-TextArea::-webkit-scrollbar-thumb:hover {
    background: #747474;
}
.chatting-Body-TextArea::-webkit-scrollbar-corner {
    background: #f6f6f6;
}
.textArea-Item {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}
/*** center ***/
.textArea-CenterItem {
    text-align: center;
}
.textArea-CenterItem-Notice {
    display: inline-block;
    margin: 0 auto;
    width: auto;
    background-color: gray;
    border-radius: 5px;
    padding: 2px 10px;
    color: white;
    font-size: 12px;
}
/*** left ***/
.textArea-LeftItem-ProfilePhoto {
    width: 40px;
    height: 40px;
    float: left;
}
.textArea-LeftItem-ProfilePhoto .img {
    width: 100%;
    height: 100%;
    display: block;
}
.textArea-LeftItem-Triangle {
    margin-left: 5px;
    margin-top: 15px;
    float: left;
    width: 0;
    height: 0;
    border-bottom: transparent solid 5px;
    border-top: transparent solid 5px;
    border-right: #DDDDDD solid 10px;
}
.textArea-LeftItem-MsgBox {
    text-align: left;
    margin-right: 55px;
    margin-left: 55px;
}
.textArea-LeftItem-MsgDialog {
    width:auto;
    display:inline-block;
    padding: 7px;
    border-radius: 5px;
    background-color: #DDDDDD;
    font-size: 12px;
    color: #666;
    word-break:break-all;
    overflow: hidden;
}
/*** right ***/
.textArea-RightItem-ProfilePhoto {
    width: 40px;
    height: 40px;
    float: right;
}
.textArea-RightItem-ProfilePhoto .img {
    width: 100%;
    height: 100%;
    display: block;
}
.textArea-RightItem-Triangle {
    margin-right: 5px;
    margin-top: 15px;
    float: right;
    width: 0;
    height: 0;
    border-bottom: transparent solid 5px;
    border-top: transparent solid 5px;
    border-left: #4cb0f9 solid 10px;
}
.textArea-RightItem-MsgBox {
    margin-right: 55px;
    margin-left: 55px;
    text-align: right;
    overflow: hidden;
}
.textArea-RightItem-MsgDialog {
    font-size: 12px;
    color: #666;
    width:auto;
    display:inline-block;
    padding: 7px;
    border-radius: 5px;
    background-color: #4cb0f9;
    word-break:break-all;
    overflow: hidden;
    height: auto;
    float: right;
}
.textArea-Flag {
    float: right;
    margin-top: 7px;
    margin-right: 7px;
}
.textArea-TextFlag {
    background-color: grey;
    padding: 3px;
    font-size: 12px;
    border-radius: 5px;
    color: whitesmoke;
}
.textArea-ImgFlag {
    width: 20px;
    height: 20px;
}
/*chatting-Send*/
.chatting-Footer {
    cursor: default;
    min-width: 350px;
    border-top: #BEBEBE solid 1px;
    background-color: white;
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
    border-radius: 0 0 10px 10px;
}
.chatting-Footer-Upload {
    width: 100%;
    text-align: left;
    margin-left: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    margin-top: 3px;
}
.chatting-Footer-UploadItem {
    background-color: #BEBEBE;
    border-radius: 5px;
    cursor: pointer;
    padding: 3px;
    font-size: 12px;
    color: white;
    margin-right: 5px;
}
.chatting-Footer-SendBox {
    margin: 5px;
    margin-top: 0px;
}
.sendBox-TextArea {
    width: 100%;
    resize: none;
    height: 80px;
    border-style: none;
    outline: none;
}


/*  customer service -- user  */
.chatBox {
    position: fixed;
    right: 150px;
    top: 50px;
    width: 350px;
}

.csRequestBox {
    background-color: white;
    border-radius: 5px;
    border: 2px black solid;
    height: 46px;
    position: fixed;
    right: 100px;
    bottom: 100px;
    width: 200px;
    overflow: hidden;
}

.csRequestText {
    padding: 5px;
    width: 150px;
    resize: none;
    height: 36px;
    border-style: none;
    outline: none;
}

.csRequestBtn {
    width: 40px;
    height: 48px;
    float: right;
    border: 0;
}

.csRequestWaiting {
    display: inline-block;
    padding: 5px;
    width: 150px;
    height: 36px;
    font-size: 13px;
}

.msgBox {
    float: left;
    width: 350px;
    margin: 5px;
    border: 3px solid black;
    border-radius: 10px;
    padding: 0;
    background-color: black
}

.staffInfoBox {
    float: right;
    width: 120px;
    height: 200px;
    margin: 5px;
    border: 2px solid black;
    background: white;
    border-radius: 10px;
    text-align: center;
}

.csB-Item {
    text-align: center;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 20px;
}
.csB-Item-Title {
    width: 100%;
    background: #1B9AF7;
}
.csB-Item-Content {
    width: 100%;
    background: black;
    color: white;
}

.waiting {
    position: absolute;
    width: 100%;
    height: 800px;
    z-index: 100;
    background: rgba(102,102,102, 0.6);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99666666, endColorstr=#99666666);
}

/*    start customer service button     */
.customerService {
    cursor: pointer;
    position: fixed;
    z-index: 99999;
    right: 50px;
    bottom: 100px;
    width: 50px;
    height: 50px;
}

.cs-showBtn {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
}

.cs-showBtn :hover {
    cursor: pointer;
}

/*        customer service -----staff          */
.staffChatBox {
    text-align: right;
    margin: 0 auto;
    width: 800px;
}
.staffContactBox {
    width: 200px;
    float: left;
}
.staffMsgBox {
    margin-left: 230px;
    position: relative;
}
.contactBox-Item {
    position: relative;
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    background: whitesmoke;
    border: 3px solid black;
    border-radius: 5px;
}
.sign-new-msg {
    background: red;
    position: absolute;
    right: 5px;
    top: 5px;
    line-height: 20px;
    color: white;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 8px;
    border-radius: 50%;
}
.cs-send-btn {
    cursor: pointer;
    outline: none;
    background-color: black;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
#content {
    clear: both;
}
.order-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.order-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.order-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.order-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.o-cart-head {
    margin-top: 20px;
    font-size: 12px;
    position: relative;
    border: 1px solid #bbb;
    background-color: #eee;
    padding: 5px 0px;
}
.order-receiveAddr-box {
    font-size: 12px;
}
#content {
    clear: both;
}
.register-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.register-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.register-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.register-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.register-form {
    margin: 50px auto;
    width: 450px;
    position: relative;
}
.form-item {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 1px dotted #999;
}
.form-title {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: black;
    padding-bottom: 20px;
}
.item-head {
    font-size: 12px;
    color: #999;
    vertical-align: middle;
}
.item-content {
    
}
.form-input {
    font-size: 12px;
    width: 400px;
    height: 18px;
    outline: none;
    padding: 10px;
    border: none;
    vertical-align: middle;
}
.form-register-btn {
    cursor: pointer;
    outline: none;
    background-color: black;
    height: 35px;
    width: 100%;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.register-form-error {
    color: red;
    margin-bottom: 10px;
    font-size: 12px;
}
.nav {
    position: relative;
    width: 800px;
    margin: 5px auto;
    margin-bottom: 15px;
    display: block;
    list-style-type: disc;
}
.nav::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.nav li {
    position: relative;
    list-style: none;
    float: left;
    padding: 5px 15px;
    color: #666;
    border-bottom: 1px solid #ddd;
}
.nav-category-item {
    cursor: default;
    
}
.nav-category-item:hover {
    padding: 4px 14px 6px 14px;
    border: 1px solid #ddd;
    border-bottom: 0px;
    background-color: white;
}
.nav .spacer {
    margin-top: 5px;
    overflow: hidden;
    width: 1px;
    height: 10px;
    margin-bottom: 6px;
    background-color: gray;
}
.nav-dropdown {
    border: 1px solid #ddd;
    border-top: 0px;
    font-size: 12px;
    color: #999;
    width: 200px;
    background-color: white;
    top: 31px;
    left: -1px;
    position: absolute;
    padding: 10px;
    z-index: 10;
    box-shadow: 1px 2px 2px #999;
}
.nav-dropdown-item {
    float: left;
    margin: 5px 0px 5px 30px;
}
.nav-dropdown-item:hover {
    color: red;
}
#content {
    clear: both;
}
.slider {
    position: relative;
}
.slider-list {
    position: relative;
    height: 450px;
}
.slider-dots {
    z-index: 1001;
    overflow: hidden;
    position: absolute;
    bottom: 10px;
    left: 20px;
}
.slider-dot {
    cursor: pointer;
    opacity: 0.15;
    border-radius: 50%;
    list-style: none;
    margin: 5px;
    float: left;
    width: 10px;
    height: 10px;
    background-color: black;
}
.slider-dot-check {
    background-color: white;
    opacity: 1;
}

.category-content {
    position: relative;
    clear: both;
}
.category-box {
    overflow: hidden;
    position: relative;
    width: 1000px;
    height: 310px;
    clear: both;
    margin-top: 20px;
}
.category-box-item {
    cursor: pointer;
    background-color: white;
    width: 220px;
    height: 245px;
    margin: 25px 0px 25px 24px;
    float: left;
}
.category-box-item:hover {
    box-shadow:2px 2px 7px #777;
}
.category-box-item p {
    margin: 7px 0px;
    margin-left: 20px;
}
.category-box-item-img {
    margin-left: 10px;
    width: 200px;
    height: 200px;
}

.search-box {
    padding: 20px 0px;
    position: relative;
}
.search-logo {
    margin-left: 40px;
    width: 100px;
    height: 34px;
    display: inline-block;
    vertical-align: middle;
}
.search-input {
    margin-left: 60px;
    font-size: 15px;
    width: 500px;
    height: 18px;
    outline: none;
    padding: 6px;
    border: 2px solid red;
    vertical-align: middle;
}
.search-btn {
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 34px;
    width: 60px;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.my-cart-box {
    z-index: 2;
    position: absolute;
    right: 50px;
    top: 20px;
}
.my-cart-content {
    position: relative;
    width: 150px;
    height: 34px;
    background-color: white;
    border: 1px solid #ddd;
    font-size: 12px;
    color: red;
    line-height: 34px;
    text-align: center;
}
.my-cart-count {
    position: absolute;
    top: 5px;
    right: 30px;
    color: white;
    background-color: red;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
}
.my-cart-dropdown {
    position: absolute;
    background-color: white;
    width: 300px;
    right: 0px;
    text-align: center;
    box-shadow: 1px 2px 1px #999;
    clear: both;
}
.my-cart-empty {
    font-size: 12px;
    height: 50px;
    margin: auto;
    padding: 10px;
    line-height: 50px;
    color: #999;
}
.my-cart-item {
    overflow: hidden;
    padding: 5px;
    position: relative;
}
.my-cart-item:hover {
    background-color: #eee;
}
.my-cart-item-left {
    float: left;
    position: relative;
}
.my-cart-item-img {
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
}
.my-cart-item-title {
    cursor: pointer;
    margin-left: 10px;
    font-size: 12px;
    color: #999;
    display: inline-block;
    width: 150px;
    height: 50px;
    overflow: hidden;
}
.my-cart-item-title:hover {
    color: red;
}
.my-cart-item-right {
    float: right;
    position: relative;
}
.my-cart-item-price {
    font-size: 12px;
    font-weight: 300px;
    color: red;
}
.my-cart-item-delete {
    cursor: pointer;
    font-size: 12px;
    color: black;
}
.my-cart-item-delete:hover {
    color: red;
}
#content {
    clear: both;
}
.commodities-content {
    position: relative;
    clear: both;
    overflow: hidden;
    padding: 10px 0px;
}
.commodity-box {
    cursor: pointer;
    float: left;
    width: 180px;
    height: 270px;
    margin-left: 19px;
    overflow: hidden;
}
.commodity-box:hover {
    box-shadow: 1px 1px 7px rgb(0, 0, 0);
}
.commodity-img {
    margin: 10px 10px 5px 10px;
    min-height: 180px;
    min-width: 160px;
    width: 160px;
    height: 180px;
}
.commodity-price {
    margin: 0px 0px 0px 10px;
    color: red;
    font-size: 20px;
    font-weight: 500;
}
.commodity-title {
    overflow: hidden;
    width: 160px;
    height: 32px;
    font-size: 12px;
    margin: 0px 10px 10px 10px;
}
.commodity-title:hover {
    color: red;
}
.rb-c-box {
    position: relative;
    padding: 10px 0px;
    width: 1000px;
    height: 290px;
}
.header {
    width: 100%;
    height: 30px;
    background-color: #e3e4e5;
    font-size: 12px;
    color: #999;
}
.navigation {
    margin: 0 auto;
}
.navigation ul {
    line-height: 30px;
}
.navigation ul>li {
    list-style: none;
    line-height: 30px;
    margin-right: 10px;
}
.navigation-li-right {
    float: right;
}
.navigation-li-left {
    float: left;
}
.navigation ul>li a {
    color: #999;
}
.navigation ul>li a:hover {
    color: red;
}
.navigation ul .spacer {
    margin-top: 9px;
    overflow: hidden;
    width: 1px;
    height: 12px;
    background-color: #ccc;
    padding: 0;
}
.header-user {
    height: 30px;
    padding: 0px 10px;
    border: 1px solid #e3e4e5;
    border-width: 0px 1px 0px 1px;
    position: relative;
    z-index: 5;
}
.header-user:hover {
    border-color: #ccc;
    background-color: white;
}
.header-dropdown {
    border: 1px solid #ccc;
    border-top: 0px;
    width: 70px;
    right: -1px;
    padding: 10px;
    background-color: white;
    position: absolute;
    top: 29px;
    color: #999;
    box-shadow: 1px 2px 1px #999;
}
.header-dropdown-item {
    cursor: pointer;
    margin: 5px;
}
.header-dropdown-item:hover {
    color: red;
}
#content {
    clear: both;
}
.commodity-intro {
    padding: 10px 0px;
    overflow: hidden;
    clear: both;
    position: relative;
}
.preview-wrap {
    position: relative;
    float: left;
    width: 300px;
    height: 300px;
}
.preview-img {
    width: 280px;
    height: 280px;
    margin: 10px;
}
.itemInfo-wrap {
    position: relative;
    float: left;
    width: 650px;
    margin-left: 20px;
}
.itemInfo-head {
    margin-left: 10px;
    width: 50px;
    text-align: center;
    color: #999;
    font-size: 12px;
    display: inline-block;
}
.itemInfo-title {
    font-weight: 700;
    margin-top: 20px;
    font-size: 18px;
}
.itemInfo-setPoint {
    font-size: 13px;
    color: red;
    margin-top: 5px;
}
.itemInfo-priceBox {
    margin-top: 10px;
    background-color: #f3f3f3;
    color: #999;
    font-size: 12px;
    height: 35px;
    line-height: 35px;
}
.itemInfo-price {
    margin-left: 40px;
    font-weight: 500;
    font-size: 20px;
    color: red;
}
.itemInfo-box {
    color: #999;
    font-size: 12px;
    margin-top: 10px;
}
.itemInfo-content {
    font-size: 12px;
    margin-left: 40px;
}
.itemInfo-num {
    font-size: 15px;
    margin-left: 50px;
}
.itemInfo-ins {
    position: relative;
    margin-top: 10px;
    clear: both;
}
.ins-head {
    color: #999;
    font-size: 12px;
    margin-right: 10px;
}
.ins-item {
    font-weight: 200;
    padding: 7px;
    border: 1px solid grey;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
}
.ins-item:hover {
    border: 1px solid red;
}
.ins-item-checking {
    border: 1px solid red;
}
.split-line {
    margin-top: 20px;
    width: 100%;
    height: 0px;
    border-bottom: 1px dotted #aaa;
}
.itemInfo-btnBox {
    margin-top: 20px;
}
.itemInfo-btn {
    font-weight: 700;
    cursor: pointer;
    outline: none;
    background-color: red;
    padding: 12px 17px;
    font-size: 20px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.buyCount-box {
    vertical-align: middle;
    display: inline-block;
    width: 70px;
    height: 50px;
    margin: 0 10px;
}
.buyCount-count {
    width: 50px;
    height: 50px;
    float: left;
}
.buyCount-count-input {
    width: 49px;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    outline: none;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #aaa;
}
.buyCount-operation {
    float: left;
    font-size: 15px;
    font-weight: 200;
    border-width: 1px;
    border-style: solid;
    border-color: #aaa;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(190, 178, 178);
}
.buyCount-add {
    cursor: pointer;
    width: 18px;
    height: 24px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #aaa;
}
.buyCount-subtract {
    cursor: pointer;
    width: 18px;
    height: 23px;
}
.unclickable {
    color: #999;
    cursor: not-allowed;
}
.buy-notice {
    vertical-align: bottom;
    font-size: 12px;
    color: #999;
    margin-left: 15px;
}
.cmdt-tab-box {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.cmdt-tab-box ul {
    background-color: #f7f7f7;
    overflow: hidden;
    position: relative;
}
.cmdt-tab-box-item {
    cursor: pointer;
    color: #666;
    text-align: center;
    float: left;
    list-style: none;
    padding: 10px 30px;
}
.cmdt-tab-box-item:hover {
    color: red;
}
.cmdt-tab-checking {
    float: left;
    text-align: center;
    list-style: none;
    background-color: red;
    color: white;
    padding: 10px 30px;
}
.cmdt-tab-desc {
    width: 800px;
    margin: 15px auto;
}
.cmdt-tab-desc div {
    margin: 0;
}
.cmdt-tab-desc div img {
    display: block;
    max-width: 800px;
    margin: 0 auto;
}
.cmdt-tab-comment {
    width: 800px;
    margin: 0 auto;
}
.cmdt-tab-comment-head {
    margin-top: 30px;
    padding: 5px 0px 5px 15px;
    background-color: #f7f7f7;
    color: #666;
    font-weight: 700;
}
.cmdt-tab-comment-avgStarLevel {
    margin: 20px 50px;
    font-size: 12px;
    color: #999;
}
.comment-item {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
    position: relative;
    overflow: hidden;
}
.comment-item-userInfo {
    padding-left: 30px;
    width: 150px;
    position: relative;
    overflow: hidden;
    float: left;
}
.comment-item-headImg {
    margin-right: 20px;
    vertical-align: middle;
    display: inline-block;
    width: 30px;
    height: 30px;
}
.comment-item-content {
    float: left;
}
.comment-item-starLevel {
    font-size: 12px;
    color: #999;
}
.comment-item-desc {
    color: #666;
    padding: 20px 0px;
}
.comment-item-updated {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
}
.cmdt-tab-comment-no-comment {
    padding: 50px 50px;
    font-size: 15px;
    color: #999;
}
#content {
    clear: both;
}
.cart-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.cart-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.cart-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.cart-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.cart-notice-text {
    border: 3px dotted rgb(241, 98, 32);
    width: 700px;
    height: 400px;
    line-height: 400px;
    text-align: center;
    color: rgb(241, 98, 32);
    font-size: 20px;
    font-weight: 700;
    margin: 50px auto;
}
.cart-box {
    position: relative;
}
.cart-box-head {
    margin-top: 20px;
    font-size: 12px;
    position: relative;
    border: 1px solid #bbb;
    background-color: #eee;
    padding: 5px 0px;
}
.cart-item {
    margin: 10px 0px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    font-size: 12px;
    color: #111;
    border: 2px;
    border-left: 0px;
    border-right: 0px;
    border-style: solid;
    border-color: #999;
}
.cart-cell {
    float: left;
    padding: 5px;
}
.cart-item-img {
    width: 100px;
    height: 100px;
}
.cart-item-title {
    cursor: pointer;
    width: 200px;
}
.cart-item-title:hover {
    color: red;
}
.cart-item-params {
    margin-left: 30px;
    width: 150px;
}
.cart-item-price {
    text-align: center;
    margin-left: 30px;
    width: 100px;
}
.cart-item-num {
    width: 100px;
}
.cart-item-total {
    margin-left: 30px;
    width: 100px;
}
.cart-item-btns {
    text-align: center;
    width: 50px;
}
.cart-item-btn-delete {
    cursor: pointer;
}
.cart-item-btn-delete:hover {
    color: red;
}
.cart-box-foot {
    overflow: hidden;
    position: relative;
    padding: 5px;
    border: 1px solid #ddd;
    border-left: 0px;
    border-right: 0px;
}
.cart-box-foot-total {
    margin-top: 4px;
    float: right;
    color: red;
    font-size: 18px;
    font-weight: 500;
    margin-right: 50px;
}
.cart-box-foot-total-head {
    font-size: 12px;
    color: #999;
}
.cart-box-foot-btn {
    text-align: center;
    display: block;
    float: right;
    margin-right: 20px;
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 34px;
    width: 60px;
    font-size: 16px;
    line-height: 34px;
    border-radius: 0;
    border: none;
    color: white;
}
#content {
    clear: both;
}
.user-col-menu {
    width: 200px;
    float: left;
}
.user-col-content {
    border: 1px solid #ddd;
    width: 798px;
    float: right;
}
.user-menu {
    width: 200px;
}
.user-menu-head {
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    border: 1px solid #ddd;
    border-bottom: 0px;
}
.user-menu-tree {
    border: 1px solid #ddd;
}
.user-menu-tree li {
    list-style: none;
}
.user-menu-tree li>a {
    display: block;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
}
.user-menu-tree li:hover {
    background-color: #ddd;
}
.user-menu-tree-item-checking {
    cursor: default;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: white;
    background-color: red;
}
.user-menu-tree li a {
    text-decoration: none;
    list-style: none;
    font-size: 12px;
}

.user-info-box {
    position: relative;
}
.user-box-head {
    position: relative;
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
    font-size: 15px;
    vertical-align: middle;
}
.user-box-head-icon {
    float: left;
    position: absolute;
    top: 5px;
    background-color: red;
    left: 10px;
    width: 5px;
    height: 18px;
    margin-top: 4px;
    vertical-align: middle;
}

.info-box-item {
    margin: 40px 0px;
    overflow: hidden;
    clear: both;
    position: relative;
}
.info-box-item-head {
    margin-top: 7px;
    font-size: 14px;
    color: #48576a;
    text-align: right;
    width: 150px;
    float: left;
    vertical-align: middle;
}
.info-box-item-content {
    vertical-align: middle;
    margin-left: 15px;
    float: left;
}
.info-box-item-input {
    height: 35px;
    width: 250px;
    font-size: inherit;
    background-image: none;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: 0;
    padding: 5px 4px;
}
.info-box-split-line {
    height: 0;
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #ddd;
}
.info-box-save-btn {
    text-align: center;
    line-height: 35px;
    margin: 40px auto;
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 35px;
    width: 100px;
    font-size: 16px;
    border-radius: 0;
    border: none;
    color: white;
}


.receiverAddr-table {
    margin: 30px auto;
    font-size: 12px;
    border-collapse: collapse;
    border: 1px solid #ddd;
}
.receiverAddr-tr td {
    padding: 12px 16px;
    border: 1px solid #ddd;
}
.receiverAddr-tr th {
    text-align: left;
    font-weight: 400;
    padding-left: 10px;
    padding: 6px 16px;
    border: 1px solid #ddd;
}
.receiverAddr-head th {
    background-color: #ebecf0;
}
.addr-notice-text {
    margin: 30px 0px 30px 30px;
    font-size: 15px;
    color: #999;
}


.myOrder-tab-box {
    clear: both;
    overflow: hidden;
    position: relative;
}
.myOrder-tab-box-item {
    cursor: pointer;
    float: left;
    text-align: center;
    color: #666;
    padding: 10px 20px;
}
.myOrder-tab-box-item-checking {
    color: white;
    background-color: red;
}
.myOrder-head {
    overflow: hidden;
    font-size: 14px;
    background-color: #f5f5f5;
    color: #666;
}
.myOrder-head>div {
    padding: 10px;
    float: left;
    text-align: center;
}
.myOrder-item {
    position: relative;
    border: 1px solid #ddd;
    margin: 10px 0px;
}
.myOrder-item:hover {
    border-color: #999;
}
.myOrder-item-head {
    font-size: 12px;
    color: #666;
    background-color: #f5f5f5;
    padding: 10px;
}
.myOrder-commodity-item {
    font-size: 12px;
    overflow: hidden;
    position: relative;
}
.myOrder-commodity-item>div {
    padding: 10px;
}
.myOrder-commodity-info {
    width: 295px;
    float: left;
    overflow: hidden;
    position: relative;
}
.myOrder-commodity-img {
    float: left;
    width: 80px;
    height: 80px;
}
.myOrder-commodity-title {
    margin-left: 15px;
    float: left;
    width: 200px;
    color: #999;
}
.myOrder-commodity-title:hover {
    color: red;
    cursor: pointer;
}
.myOrder-commodity-price {
    width: 100px;
    text-align: center;
    float: left;
}
.myOrder-commodity-num {
    width: 50px;
    text-align: center;
    float: left;
}
.myOrder-commodity-status {
    width: 100px;
    text-align: center;
    float: left;
}
.myOrder-commodity-status-i-d {
    margin-top: 5px;
    cursor: pointer;
}
.myOrder-commodity-status-i-d:hover {
    color: red;
}
.myOrder-commodity-control {
    text-align: center;
    width: 100px;
    float: left;
}
.myOrder-i-d-box {
    position: absolute;
    width: 800px;
    height: 100%;
    z-index: 100;
    background: rgba(102,102,102, 0.6);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99666666, endColorstr=#99666666);
}
.myOrder-i-d-quit {
    position: absolute;
    right: 5%;
    top: 5%;
    font-size: 50px;
}
.myOrder-i-d-quit:before {
    content: "\2715";
    cursor: pointer;
    color: #4cb0f9;
}
.myOrder-i-d-content {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.myOrder-i-d-c-head {
    background-color: white;
    padding: 10px 0px;
    font-size: 15px;
    color: #666;
    width: 100%;
    text-align: center;
}
.myOrder-i-d-c-content {
    background-color: #ebecf0;
    font-size: 12px;
    color: #999;
    max-height: 500px;
    overflow-y: scroll;
}
.myOrder-i-d-c-c-item {
    margin-top: 1px;
    padding: 10px;
    overflow: hidden;
    background-color: white;
    position: relative;
}
.myOrder-i-d-c-c-i-left {
    float: left;
    position: relative;
}
.myOrder-i-d-c-c-i-right {
    margin-top: 8px;
    float: right;
}
.myOrder-i-d-c-c-i-bottom {
    color: #666;
}
.myOrder-i-d-c-c-btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: red;
    padding: 10px 0px;
    font-size: 16px;
    color: white;
}


.comment-commodity-info-box {
    margin: 20px 0px;
    padding: 10px;
    border: 2px solid #666;
    border-width: 1px 0px 1px 0px;
}
.comment-commodity-info-img {
    width: 150px;
    height: 150px;
}
.comment-commodity-info-title {
    margin: 10px 30px;
    vertical-align: top;
    display: inline-block;
    font-size: 15px;
    color: #666;
}
.comment-box {
    width: 600px;
    margin: 0 auto;
}
.comment-head {
    color: #666;
    font-size: 15px;
    vertical-align: middle;
}
.comment-stars-ul {
    margin-left: 50px;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    position: relative;
}
.comment-stars-ul li {
    margin: 0px 2px;
    font-size: 30px;
    cursor: pointer;
    list-style: none;
    float: left;
}
.comment-desc {
    display: inline-block;
    resize: none;
    vertical-align: top;
    width: 450px;
    margin-left: 50px;
    padding: 10px;
    height: 200px;
}
.comment-btn {
    margin: 20px auto;
    width: 100px;
    text-align: center;
    cursor: pointer;
    background-color: red;
    padding: 10px 15px;
    font-size: 16px;
    color: white;
}
.pay-btns-box {
    width: 260px;
    margin: 0 auto;
}
.pay-btn {
    margin: 0px 15px;
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 34px;
    width: 100px;
    font-size: 16px;
    border-radius: 0;
    border: none;
    color: white;
}
.admin-nav {
    overflow: hidden;
    border-bottom: 1px solid red;
}
.admin-nav-ul {
    position: relative;
    margin: 5px auto;
    display: block;
    list-style-type: disc;
    color: #999;
}
.admin-nav-ul li {
    cursor: pointer;
    position: relative;
    list-style: none;
    float: left;
    padding: 5px 15px;
}
.admin-nav-checking {
    background-color: red;
    color: white;
}


.o-m-box {
    position: relative;
}
.o-m-left {
    width: 200px;
    position: relative;
    float: left;
}
.o-m-right {
    width: 800px;
    position: relative;
    float: right;
}
.o-m-right-head {
    padding: 10px 0px 5px 15px;
    color: #666;
}
.o-m-right-head-icon {
    vertical-align: middle;
    margin-right: 10px;
    height: 20px;
    display: inline-block;
    width: 3px;
    background-color: red;
}
.o-m-left-ul {
    color: #666;
    overflow: hidden;
    position: relative;
    margin: 5px auto;
    display: block;
    list-style-type: disc;
}
.o-m-left-ul li {
    cursor: pointer;
    text-align: center;
    width: 100%;
    position: relative;
    list-style: none;
    float: left;
    padding: 5px 0px;
    border-bottom: 1px solid #ddd;
}
.o-m-left-ul-li-checking {
    color: red;
}
.o-m-unfinished-order-content {
    position: relative;
}
.o-m-u-o-i-head {
    color: #666;
    background-color: #f5f5f5;
    position: relative;
    font-size: 12px;
    padding: 5px 0px;
}
.o-m-u-o-i-head span {
    text-align: center;
    display: inline-block;
}
.o-m-u-o-item {
    margin: 5px 0px;
    border: 1px solid #ddd;
    position: relative;
    overflow: hidden;
}
.o-m-u-o-i-content {
    float: none;
    position: relative;
    overflow: hidden;
}
.o-m-u-o-i-content div {
    float: left;
}
.o-m-u-o-item:hover {
    border-color: #999;
}
.o-m-u-o-i-id {
    text-align: center;
    width: 100px;
}
.o-m-u-o-i-created {
    color: #666;
    text-align: center;
    width: 300px;
}
.o-m-u-o-i-control {
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 150px;
}
.o-m-u-o-i-control div {
    cursor: pointer;
    font-size: 12px;
    color: #999;
    float: none;
    display: block;
    margin: 5px;
}
.o-m-u-o-i-control div:hover {
    color: red;
}
* {
    margin: 0;
    padding: 0;
}
