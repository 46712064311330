#content {
    clear: both;
}
.order-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.order-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.order-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.order-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.o-cart-head {
    margin-top: 20px;
    font-size: 12px;
    position: relative;
    border: 1px solid #bbb;
    background-color: #eee;
    padding: 5px 0px;
}
.order-receiveAddr-box {
    font-size: 12px;
}