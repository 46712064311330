.nav {
    position: relative;
    width: 800px;
    margin: 5px auto;
    margin-bottom: 15px;
    display: block;
    list-style-type: disc;
}
.nav::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.nav li {
    position: relative;
    list-style: none;
    float: left;
    padding: 5px 15px;
    color: #666;
    border-bottom: 1px solid #ddd;
}
.nav-category-item {
    cursor: default;
    
}
.nav-category-item:hover {
    padding: 4px 14px 6px 14px;
    border: 1px solid #ddd;
    border-bottom: 0px;
    background-color: white;
}
.nav .spacer {
    margin-top: 5px;
    overflow: hidden;
    width: 1px;
    height: 10px;
    margin-bottom: 6px;
    background-color: gray;
}
.nav-dropdown {
    border: 1px solid #ddd;
    border-top: 0px;
    font-size: 12px;
    color: #999;
    width: 200px;
    background-color: white;
    top: 31px;
    left: -1px;
    position: absolute;
    padding: 10px;
    z-index: 10;
    box-shadow: 1px 2px 2px #999;
}
.nav-dropdown-item {
    float: left;
    margin: 5px 0px 5px 30px;
}
.nav-dropdown-item:hover {
    color: red;
}
#content {
    clear: both;
}
.slider {
    position: relative;
}
.slider-list {
    position: relative;
    height: 450px;
}
.slider-dots {
    z-index: 1001;
    overflow: hidden;
    position: absolute;
    bottom: 10px;
    left: 20px;
}
.slider-dot {
    cursor: pointer;
    opacity: 0.15;
    border-radius: 50%;
    list-style: none;
    margin: 5px;
    float: left;
    width: 10px;
    height: 10px;
    background-color: black;
}
.slider-dot-check {
    background-color: white;
    opacity: 1;
}

.category-content {
    position: relative;
    clear: both;
}
.category-box {
    overflow: hidden;
    position: relative;
    width: 1000px;
    height: 310px;
    clear: both;
    margin-top: 20px;
}
.category-box-item {
    cursor: pointer;
    background-color: white;
    width: 220px;
    height: 245px;
    margin: 25px 0px 25px 24px;
    float: left;
}
.category-box-item:hover {
    box-shadow:2px 2px 7px #777;
}
.category-box-item p {
    margin: 7px 0px;
    margin-left: 20px;
}
.category-box-item-img {
    margin-left: 10px;
    width: 200px;
    height: 200px;
}

.search-box {
    padding: 20px 0px;
    position: relative;
}
.search-logo {
    margin-left: 40px;
    width: 100px;
    height: 34px;
    display: inline-block;
    vertical-align: middle;
}
.search-input {
    margin-left: 60px;
    font-size: 15px;
    width: 500px;
    height: 18px;
    outline: none;
    padding: 6px;
    border: 2px solid red;
    vertical-align: middle;
}
.search-btn {
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 34px;
    width: 60px;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.my-cart-box {
    z-index: 2;
    position: absolute;
    right: 50px;
    top: 20px;
}
.my-cart-content {
    position: relative;
    width: 150px;
    height: 34px;
    background-color: white;
    border: 1px solid #ddd;
    font-size: 12px;
    color: red;
    line-height: 34px;
    text-align: center;
}
.my-cart-count {
    position: absolute;
    top: 5px;
    right: 30px;
    color: white;
    background-color: red;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
}
.my-cart-dropdown {
    position: absolute;
    background-color: white;
    width: 300px;
    right: 0px;
    text-align: center;
    box-shadow: 1px 2px 1px #999;
    clear: both;
}
.my-cart-empty {
    font-size: 12px;
    height: 50px;
    margin: auto;
    padding: 10px;
    line-height: 50px;
    color: #999;
}
.my-cart-item {
    overflow: hidden;
    padding: 5px;
    position: relative;
}
.my-cart-item:hover {
    background-color: #eee;
}
.my-cart-item-left {
    float: left;
    position: relative;
}
.my-cart-item-img {
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
}
.my-cart-item-title {
    cursor: pointer;
    margin-left: 10px;
    font-size: 12px;
    color: #999;
    display: inline-block;
    width: 150px;
    height: 50px;
    overflow: hidden;
}
.my-cart-item-title:hover {
    color: red;
}
.my-cart-item-right {
    float: right;
    position: relative;
}
.my-cart-item-price {
    font-size: 12px;
    font-weight: 300px;
    color: red;
}
.my-cart-item-delete {
    cursor: pointer;
    font-size: 12px;
    color: black;
}
.my-cart-item-delete:hover {
    color: red;
}