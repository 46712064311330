#content {
    clear: both;
}
.commodity-intro {
    padding: 10px 0px;
    overflow: hidden;
    clear: both;
    position: relative;
}
.preview-wrap {
    position: relative;
    float: left;
    width: 300px;
    height: 300px;
}
.preview-img {
    width: 280px;
    height: 280px;
    margin: 10px;
}
.itemInfo-wrap {
    position: relative;
    float: left;
    width: 650px;
    margin-left: 20px;
}
.itemInfo-head {
    margin-left: 10px;
    width: 50px;
    text-align: center;
    color: #999;
    font-size: 12px;
    display: inline-block;
}
.itemInfo-title {
    font-weight: 700;
    margin-top: 20px;
    font-size: 18px;
}
.itemInfo-setPoint {
    font-size: 13px;
    color: red;
    margin-top: 5px;
}
.itemInfo-priceBox {
    margin-top: 10px;
    background-color: #f3f3f3;
    color: #999;
    font-size: 12px;
    height: 35px;
    line-height: 35px;
}
.itemInfo-price {
    margin-left: 40px;
    font-weight: 500;
    font-size: 20px;
    color: red;
}
.itemInfo-box {
    color: #999;
    font-size: 12px;
    margin-top: 10px;
}
.itemInfo-content {
    font-size: 12px;
    margin-left: 40px;
}
.itemInfo-num {
    font-size: 15px;
    margin-left: 50px;
}
.itemInfo-ins {
    position: relative;
    margin-top: 10px;
    clear: both;
}
.ins-head {
    color: #999;
    font-size: 12px;
    margin-right: 10px;
}
.ins-item {
    font-weight: 200;
    padding: 7px;
    border: 1px solid grey;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
}
.ins-item:hover {
    border: 1px solid red;
}
.ins-item-checking {
    border: 1px solid red;
}
.split-line {
    margin-top: 20px;
    width: 100%;
    height: 0px;
    border-bottom: 1px dotted #aaa;
}
.itemInfo-btnBox {
    margin-top: 20px;
}
.itemInfo-btn {
    font-weight: 700;
    cursor: pointer;
    outline: none;
    background-color: red;
    padding: 12px 17px;
    font-size: 20px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.buyCount-box {
    vertical-align: middle;
    display: inline-block;
    width: 70px;
    height: 50px;
    margin: 0 10px;
}
.buyCount-count {
    width: 50px;
    height: 50px;
    float: left;
}
.buyCount-count-input {
    width: 49px;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    outline: none;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #aaa;
}
.buyCount-operation {
    float: left;
    font-size: 15px;
    font-weight: 200;
    border-width: 1px;
    border-style: solid;
    border-color: #aaa;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(190, 178, 178);
}
.buyCount-add {
    cursor: pointer;
    width: 18px;
    height: 24px;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #aaa;
}
.buyCount-subtract {
    cursor: pointer;
    width: 18px;
    height: 23px;
}
.unclickable {
    color: #999;
    cursor: not-allowed;
}
.buy-notice {
    vertical-align: bottom;
    font-size: 12px;
    color: #999;
    margin-left: 15px;
}
.cmdt-tab-box {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.cmdt-tab-box ul {
    background-color: #f7f7f7;
    overflow: hidden;
    position: relative;
}
.cmdt-tab-box-item {
    cursor: pointer;
    color: #666;
    text-align: center;
    float: left;
    list-style: none;
    padding: 10px 30px;
}
.cmdt-tab-box-item:hover {
    color: red;
}
.cmdt-tab-checking {
    float: left;
    text-align: center;
    list-style: none;
    background-color: red;
    color: white;
    padding: 10px 30px;
}
.cmdt-tab-desc {
    width: 800px;
    margin: 15px auto;
}
.cmdt-tab-desc div {
    margin: 0;
}
.cmdt-tab-desc div img {
    display: block;
    max-width: 800px;
    margin: 0 auto;
}
.cmdt-tab-comment {
    width: 800px;
    margin: 0 auto;
}
.cmdt-tab-comment-head {
    margin-top: 30px;
    padding: 5px 0px 5px 15px;
    background-color: #f7f7f7;
    color: #666;
    font-weight: 700;
}
.cmdt-tab-comment-avgStarLevel {
    margin: 20px 50px;
    font-size: 12px;
    color: #999;
}
.comment-item {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
    position: relative;
    overflow: hidden;
}
.comment-item-userInfo {
    padding-left: 30px;
    width: 150px;
    position: relative;
    overflow: hidden;
    float: left;
}
.comment-item-headImg {
    margin-right: 20px;
    vertical-align: middle;
    display: inline-block;
    width: 30px;
    height: 30px;
}
.comment-item-content {
    float: left;
}
.comment-item-starLevel {
    font-size: 12px;
    color: #999;
}
.comment-item-desc {
    color: #666;
    padding: 20px 0px;
}
.comment-item-updated {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
}
.cmdt-tab-comment-no-comment {
    padding: 50px 50px;
    font-size: 15px;
    color: #999;
}