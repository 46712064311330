.header {
    width: 100%;
    height: 30px;
    background-color: #e3e4e5;
    font-size: 12px;
    color: #999;
}
.navigation {
    margin: 0 auto;
}
.navigation ul {
    line-height: 30px;
}
.navigation ul>li {
    list-style: none;
    line-height: 30px;
    margin-right: 10px;
}
.navigation-li-right {
    float: right;
}
.navigation-li-left {
    float: left;
}
.navigation ul>li a {
    color: #999;
}
.navigation ul>li a:hover {
    color: red;
}
.navigation ul .spacer {
    margin-top: 9px;
    overflow: hidden;
    width: 1px;
    height: 12px;
    background-color: #ccc;
    padding: 0;
}
.header-user {
    height: 30px;
    padding: 0px 10px;
    border: 1px solid #e3e4e5;
    border-width: 0px 1px 0px 1px;
    position: relative;
    z-index: 5;
}
.header-user:hover {
    border-color: #ccc;
    background-color: white;
}
.header-dropdown {
    border: 1px solid #ccc;
    border-top: 0px;
    width: 70px;
    right: -1px;
    padding: 10px;
    background-color: white;
    position: absolute;
    top: 29px;
    color: #999;
    box-shadow: 1px 2px 1px #999;
}
.header-dropdown-item {
    cursor: pointer;
    margin: 5px;
}
.header-dropdown-item:hover {
    color: red;
}