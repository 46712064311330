.clearfix::after {
    content: ".";
    visibility: hidden;
    overflow: hidden;
    height: 0;
    display: block;
    clear: both;
}
.chat-img {
    max-width: 100px;
    display: block;
}
.chat-img::after {
    content: ".";
    height: 0;
    visibility: hidden;
    clear: both;
}
#chattingBox {
    margin-top: 10px;
    position: relative;
    width: 100%;
}
.chatting-Head {
    border-radius: 5px 5px 0 0;
    color: white;
    background-color: black;
    width: 100%;
    min-width: 350px;
    height: 30px;
    line-height: 30px;
    cursor: move;
}
.chatting-Head-Quit {
    float: left;
    position: relative;
}
.chatting-Head-Quit:hover {
    cursor: pointer;
}
.chatting-Head-Quit-InnerA {
    margin-top: 5px;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-bottom: transparent solid 20px;
    border-top: transparent solid 20px;
    border-right: white solid 25px;
}
.chatting-Head-Quit-InnerB {
    top: 5px;
    left: 15px;
    width: 0;
    height: 0;
    border-bottom: transparent solid 20px;
    border-top: transparent solid 20px;
    border-right: black solid 25px;
    position: absolute;
}
.chatting-Head-Title {
    text-align: center;
    font-size: 20px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
.chatting-Head-Setting {
    cursor: pointer;
    float: right;
}
.chatting-Head-Setting-Inner {
    /*border: white solid 5px;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-right: 10px;*/
    height: 25px;
}
.chatting-Body {
    cursor: default;
    min-width: 350px;
    background-color: white;
    width: 100%;
}
.chatting-Body-TextArea {
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 10px;
    width: 100%;
    height: 300px;
}
.chatting-Body-TextArea::-webkit-scrollbar{
    width:5px;
    height:5px;
}
.chatting-Body-TextArea::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius:2px;
}
.chatting-Body-TextArea::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius:2px;
}
.chatting-Body-TextArea::-webkit-scrollbar-thumb:hover {
    background: #747474;
}
.chatting-Body-TextArea::-webkit-scrollbar-corner {
    background: #f6f6f6;
}
.textArea-Item {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}
/*** center ***/
.textArea-CenterItem {
    text-align: center;
}
.textArea-CenterItem-Notice {
    display: inline-block;
    margin: 0 auto;
    width: auto;
    background-color: gray;
    border-radius: 5px;
    padding: 2px 10px;
    color: white;
    font-size: 12px;
}
/*** left ***/
.textArea-LeftItem-ProfilePhoto {
    width: 40px;
    height: 40px;
    float: left;
}
.textArea-LeftItem-ProfilePhoto .img {
    width: 100%;
    height: 100%;
    display: block;
}
.textArea-LeftItem-Triangle {
    margin-left: 5px;
    margin-top: 15px;
    float: left;
    width: 0;
    height: 0;
    border-bottom: transparent solid 5px;
    border-top: transparent solid 5px;
    border-right: #DDDDDD solid 10px;
}
.textArea-LeftItem-MsgBox {
    text-align: left;
    margin-right: 55px;
    margin-left: 55px;
}
.textArea-LeftItem-MsgDialog {
    width:auto;
    display:inline-block;
    padding: 7px;
    border-radius: 5px;
    background-color: #DDDDDD;
    font-size: 12px;
    color: #666;
    word-break:break-all;
    overflow: hidden;
}
/*** right ***/
.textArea-RightItem-ProfilePhoto {
    width: 40px;
    height: 40px;
    float: right;
}
.textArea-RightItem-ProfilePhoto .img {
    width: 100%;
    height: 100%;
    display: block;
}
.textArea-RightItem-Triangle {
    margin-right: 5px;
    margin-top: 15px;
    float: right;
    width: 0;
    height: 0;
    border-bottom: transparent solid 5px;
    border-top: transparent solid 5px;
    border-left: #4cb0f9 solid 10px;
}
.textArea-RightItem-MsgBox {
    margin-right: 55px;
    margin-left: 55px;
    text-align: right;
    overflow: hidden;
}
.textArea-RightItem-MsgDialog {
    font-size: 12px;
    color: #666;
    width:auto;
    display:inline-block;
    padding: 7px;
    border-radius: 5px;
    background-color: #4cb0f9;
    word-break:break-all;
    overflow: hidden;
    height: auto;
    float: right;
}
.textArea-Flag {
    float: right;
    margin-top: 7px;
    margin-right: 7px;
}
.textArea-TextFlag {
    background-color: grey;
    padding: 3px;
    font-size: 12px;
    border-radius: 5px;
    color: whitesmoke;
}
.textArea-ImgFlag {
    width: 20px;
    height: 20px;
}
/*chatting-Send*/
.chatting-Footer {
    cursor: default;
    min-width: 350px;
    border-top: #BEBEBE solid 1px;
    background-color: white;
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
    border-radius: 0 0 10px 10px;
}
.chatting-Footer-Upload {
    width: 100%;
    text-align: left;
    margin-left: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    margin-top: 3px;
}
.chatting-Footer-UploadItem {
    background-color: #BEBEBE;
    border-radius: 5px;
    cursor: pointer;
    padding: 3px;
    font-size: 12px;
    color: white;
    margin-right: 5px;
}
.chatting-Footer-SendBox {
    margin: 5px;
    margin-top: 0px;
}
.sendBox-TextArea {
    width: 100%;
    resize: none;
    height: 80px;
    border-style: none;
    outline: none;
}


/*  customer service -- user  */
.chatBox {
    position: fixed;
    right: 150px;
    top: 50px;
    width: 350px;
}

.csRequestBox {
    background-color: white;
    border-radius: 5px;
    border: 2px black solid;
    height: 46px;
    position: fixed;
    right: 100px;
    bottom: 100px;
    width: 200px;
    overflow: hidden;
}

.csRequestText {
    padding: 5px;
    width: 150px;
    resize: none;
    height: 36px;
    border-style: none;
    outline: none;
}

.csRequestBtn {
    width: 40px;
    height: 48px;
    float: right;
    border: 0;
}

.csRequestWaiting {
    display: inline-block;
    padding: 5px;
    width: 150px;
    height: 36px;
    font-size: 13px;
}

.msgBox {
    float: left;
    width: 350px;
    margin: 5px;
    border: 3px solid black;
    border-radius: 10px;
    padding: 0;
    background-color: black
}

.staffInfoBox {
    float: right;
    width: 120px;
    height: 200px;
    margin: 5px;
    border: 2px solid black;
    background: white;
    border-radius: 10px;
    text-align: center;
}

.csB-Item {
    text-align: center;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 20px;
}
.csB-Item-Title {
    width: 100%;
    background: #1B9AF7;
}
.csB-Item-Content {
    width: 100%;
    background: black;
    color: white;
}

.waiting {
    position: absolute;
    width: 100%;
    height: 800px;
    z-index: 100;
    background: rgba(102,102,102, 0.6);
    filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#99666666, endColorstr=#99666666);
}

/*    start customer service button     */
.customerService {
    cursor: pointer;
    position: fixed;
    z-index: 99999;
    right: 50px;
    bottom: 100px;
    width: 50px;
    height: 50px;
}

.cs-showBtn {
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
}

.cs-showBtn :hover {
    cursor: pointer;
}

/*        customer service -----staff          */
.staffChatBox {
    text-align: right;
    margin: 0 auto;
    width: 800px;
}
.staffContactBox {
    width: 200px;
    float: left;
}
.staffMsgBox {
    margin-left: 230px;
    position: relative;
}
.contactBox-Item {
    position: relative;
    width: 100%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    background: whitesmoke;
    border: 3px solid black;
    border-radius: 5px;
}
.sign-new-msg {
    background: red;
    position: absolute;
    right: 5px;
    top: 5px;
    line-height: 20px;
    color: white;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 8px;
    border-radius: 50%;
}
.cs-send-btn {
    cursor: pointer;
    outline: none;
    background-color: black;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}