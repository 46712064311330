#content {
    clear: both;
}
.commodities-content {
    position: relative;
    clear: both;
    overflow: hidden;
    padding: 10px 0px;
}
.commodity-box {
    cursor: pointer;
    float: left;
    width: 180px;
    height: 270px;
    margin-left: 19px;
    overflow: hidden;
}
.commodity-box:hover {
    box-shadow: 1px 1px 7px rgb(0, 0, 0);
}
.commodity-img {
    margin: 10px 10px 5px 10px;
    min-height: 180px;
    min-width: 160px;
    width: 160px;
    height: 180px;
}
.commodity-price {
    margin: 0px 0px 0px 10px;
    color: red;
    font-size: 20px;
    font-weight: 500;
}
.commodity-title {
    overflow: hidden;
    width: 160px;
    height: 32px;
    font-size: 12px;
    margin: 0px 10px 10px 10px;
}
.commodity-title:hover {
    color: red;
}