#content {
    clear: both;
}
.register-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.register-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.register-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.register-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.register-form {
    margin: 50px auto;
    width: 450px;
    position: relative;
}
.form-item {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 1px dotted #999;
}
.form-title {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: black;
    padding-bottom: 20px;
}
.item-head {
    font-size: 12px;
    color: #999;
    vertical-align: middle;
}
.item-content {
    
}
.form-input {
    font-size: 12px;
    width: 400px;
    height: 18px;
    outline: none;
    padding: 10px;
    border: none;
    vertical-align: middle;
}
.form-register-btn {
    cursor: pointer;
    outline: none;
    background-color: black;
    height: 35px;
    width: 100%;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.register-form-error {
    color: red;
    margin-bottom: 10px;
    font-size: 12px;
}