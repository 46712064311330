#logo {
    position: relative;
    float: left;
    width: 50px;
    height: 50px;
}
#content {
    clear: both;
}
.login-head {
    width: 100%;
    border-bottom: 4px solid #888;
}
.login-head-content {
    clear: both;
    position: relative;
    margin: 20px;
    overflow: hidden;
}
.login-head-content-logo {
    width: 150px;
    height: 50px;
    float: left;
}
.login-head-content-words {
    margin-top: 5px;
    margin-left: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    float: left;
}
.login-wrapper {
    height: 500px;
    position: relative;
}
.login-form {
    padding: 20px;
    margin-top: 50px;
    position: relative;
    float: right;
    z-index: 4;
    background-color: white;
}
.login-form-item {
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
}
.login-form-title {
    margin-right: 20px;
    text-align: right;
    font-size: 20px;
    font-weight: 350;
    color: red;
    padding-bottom: 20px;
}
.login-item-head {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    float: left;
    font-size: 12px;
    color: #eee;
    background: #aaa;
}
.login-item-content {
    float: left;
}
.login-form-input {
    font-size: 12px;
    width: 250px;
    height: 18px;
    outline: none;
    padding: 10px;
    border: 1px solid #aaa;
    vertical-align: middle;
}
.form-forget-password {
    float: right;
    cursor: pointer;
    font-size: 12px;
    color: #222222;
}
.form-forget-password:hover {
    color: red;
    text-decoration: underline;
    text-decoration-color: red;
}
.form-free-register {
    margin-right: 10px;
    float: right;
    cursor: pointer;
    font-size: 12px;
    color: #222222;
}
.form-free-register:hover {
    color: red;
    text-decoration: underline;
    text-decoration-color: red;
}
.form-login-btn {
    cursor: pointer;
    outline: none;
    background-color: red;
    height: 35px;
    width: 100%;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0;
    border: none;
    color: white;
    display: inline-block;
}
.login-form-error {
    color: red;
    margin-bottom: 10px;
    font-size: 12px;
}
.login-banner {
    position: absolute;
    height: 500px;
    left: 0;
    top: 0;
    width: 100%;
}
.banner-bg {
    position: relative;
    z-index: 3;
    height: 500px;
}